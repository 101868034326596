import movimentacaoService from "../../../services/movimentacao.service";

import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";

import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import * as Yup from "yup";

import {Calendar} from "primereact/calendar";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {useMountEffect} from "primereact/hooks";
import {InputNumber} from "primereact/inputnumber";
import EdicaoMovimentacaoLoading from "./edicaoLoading.component";
import apoioService from "../../../services/apoio.service";
import contaService from "../../../services/conta.service";

const validationSchema = Yup.object().shape({
    conta: Yup.object().required("A conta é obrigatória!"),
    valor: Yup.number().required("O valor é obrigatório!").min(0.01, "O valor deve ser maior que 0!"),
    tipoMovimentacao: Yup.object().required("O tipo é obrigatório!"),
    situacaoMovimentacao: Yup.object().required("A situação é obrigatória!"),
    dataVencimento: Yup.date().required("A data de vencimento é obrigatória!"),
});

const MovimentacaoEdicao = ({mostrarMensagem}) => {
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [situacaoAtual, setSituacaoAtual] = useState(1);

    const [contas, setContas] = useState([]);
    const [tipoMovimentacao, setTipoMovimentacao] = useState([]);
    const [situacaoMovimentacao, setSituacaoMovimentacao] = useState([]);

    const formik = useFormik({
        initialValues: {
            id: 0,
            conta: null,
            contaId: null,
            tipoMovimentacao: {id: 1, descricao: "Entrada"},
            tipoMovimentacaoId: 1,
            situacaoMovimentacao: {id: 1, descricao: "Aguardando"},
            situacaoMovimentacaoId: 1,
            valor: 0,
            descricao: null,
            dataMovimentacao: new Date(),
            dataVencimento: new Date(),
        }, validationSchema, onSubmit: (values) => {
            if (values.situacaoMovimentacao.id === 2 && values.dataMovimentacao === null) {
                mostrarMensagem("Data de Pagamento é obrigatória para movimentações Concluídas!", "error");
                return;
            }
            setLoadingSubmit(true);

            if (values.id !== 0) {
                movimentacaoService
                    .alterarMovimentacao(values)
                    .then((result) => {
                        mostrarMensagem("Movimentacao alterado com sucesso!", "success");
                        setLoadingSubmit(false);
                    })
                    .catch((error) => {
                        mostrarMensagem(error, "error");
                        setLoadingSubmit(false);
                    });
            } else {
                movimentacaoService
                    .cadastrarMovimentacao(values)
                    .then((result) => {
                        const searchParams = new URLSearchParams(window.location.search);
                        searchParams.set("id", result);
                        searchParams.set("criado", true);
                        window.location.search = searchParams;
                        setLoadingSubmit(false);
                    })
                    .catch((error) => {
                        mostrarMensagem(error, "error");
                        setLoadingSubmit(false);
                    });
            }
            setSituacaoAtual(values.situacaoMovimentacao.id);
        },
    });

    useMountEffect(() => {
        contaService.resgatarContas().then((result) => setContas(result));
        apoioService.listaTipoMovimentacaoFinanceiro().then((result) => setTipoMovimentacao(result));
        apoioService.listaSituacaoMovimentacaoFinanceiro().then((result) => setSituacaoMovimentacao(result));

        const searchParams = new URLSearchParams(window.location.search);
        const idParam = searchParams.get("id");
        if (!idParam) {
            setLoading(false);
            return;
        }

        if (searchParams.has("criado")) mostrarMensagem("Movimentação cadastrada com sucesso!", "success");

        if (idParam !== 0 && idParam !== null) {
            movimentacaoService
                .pesquisarMovimentacao(idParam)
                .then((result) => {
                    setSituacaoAtual(result.situacaoMovimentacaoId);
                    formik.setValues({
                        id: result.id,
                        conta: {
                            id: result.contaId,
                            nome: result.contaNome,
                            tipoContaDescricao: result.tipoContaDescricao,
                            tipoContaId: result.tipoContaId,
                        },
                        contaId: result.contaId,
                        tipoMovimentacao: {id: result.tipoMovimentacaoId, descricao: result.tipoMovimentacaoDescricao},
                        tipoMovimentacaoId: result.tipoMovimentacaoId,
                        situacaoMovimentacao: {
                            id: result.situacaoMovimentacaoId, descricao: result.situacaoMovimentacaoDescricao
                        },
                        situacaoMovimentacaoId: result.situacaoMovimentacaoId,
                        valor: result.valor,
                        descricao: result.descricao,
                        dataMovimentacao: new Date(result.dataMovimentacao),
                        dataVencimento: new Date(result.dataVencimento),
                    });

                    setLoading(false);
                })
                .catch((error) => {
                    mostrarMensagem("Não foi encontrado nenhum Movimentacao com o ID: " + idParam, "error");
                });
        }
    }, [mostrarMensagem, formik]);

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
    };

    const accept = () => {
        formik.handleSubmit();
    };

    const confirmar = () => {
        confirmDialog({
            message: formik.values.situacaoMovimentacao.id === 2 ? "Deseja concluir a movimentação? (Não será possível desfazer)" : formik.values.situacaoMovimentacao.id === 3 ? "Deseja cancelar a movimentação? (Não será possível desfazer)" : "Deseja confirmar a movimentação?",
            header: formik.values.situacaoMovimentacao.id === 2 ? "Concluir Movimentação" : formik.values.situacaoMovimentacao.id === 3 ? "Cancelar Movimentação" : "Salvar Movimentação",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept,
        });
    };

    return (<>
        {loading ? (<EdicaoMovimentacaoLoading/>) : (
            <Card title={(formik.values.id === 0 ? "Cadastro de " : "Alteração de ") + "Movimentação"}>
                <div className="formgrid grid">
                    <div className="field col-12 lg:col-8">
                        <label htmlFor="conta">Conta</label>
                        <Dropdown
                            id="conta"
                            value={formik.values.conta}
                            onChange={(e) => {
                                formik.setFieldValue("conta", e.target.value);
                                formik.setFieldValue("contaId", e.target.value.id);
                            }}
                            options={contas}
                            optionLabel="nome"
                            placeholder="Selecione a conta"
                            className={classNames({"p-invalid": isFormFieldInvalid("conta")}, "w-full")}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                        />
                        {getFormErrorMessage("conta")}
                    </div>
                    <div className="field col-12 lg:col-4 md:col-6">
                        <label htmlFor="dataMovimentacao">Data</label>
                        <Calendar
                            id="dataMovimentacao"
                            value={formik.values.dataMovimentacao !== null ? new Date(formik.values.dataMovimentacao) : null}
                            onChange={(e) => {
                                if (e.target.value === "") e.target.value = null;
                                if (e.target.value !== null) {
                                    formik.setFieldValue("dataMovimentacao", new Date(e.value).toISOString());
                                } else {
                                    formik.setFieldValue("dataMovimentacao", null);
                                }
                            }}
                            showIcon
                            className={classNames({"p-invalid": isFormFieldInvalid("dataMovimentacao")}, "w-full")}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                            touchUI={window.innerWidth < 768}
                            showOnFocus={false}
                            mask="99/99/9999"
                        />
                        {getFormErrorMessage("dataMovimentacao")}
                    </div>
                    <div className="field col-12 lg:col-3 md:col-6">
                        <label htmlFor="dataVencimento">Vencimento</label>
                        <Calendar
                            id="dataVencimento"
                            value={formik.values.dataVencimento !== null ? new Date(formik.values.dataVencimento) : null}
                            onChange={(e) => {
                                if (e.target.value === "") e.target.value = null;
                                if (e.target.value !== null) {
                                    formik.setFieldValue("dataVencimento", new Date(e.value).toISOString());
                                } else {
                                    formik.setFieldValue("dataVencimento", null);
                                }
                            }}
                            showIcon
                            className={classNames({"p-invalid": isFormFieldInvalid("dataVencimento")}, "w-full")}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                            touchUI={window.innerWidth < 768}
                            showOnFocus={false}
                            mask="99/99/9999"
                        />
                        {getFormErrorMessage("dataVencimento")}
                    </div>
                    <div className="field col-12 lg:col-3 md:col-4">
                        <label htmlFor="tipoMovimentacao">Tipo</label>
                        <Dropdown
                            id="tipoMovimentacao"
                            value={formik.values.tipoMovimentacao}
                            onChange={(e) => {
                                formik.setFieldValue("tipoMovimentacao", e.target.value);
                                formik.setFieldValue("tipoMovimentacaoId", e.target.value.id);
                            }}
                            options={tipoMovimentacao}
                            optionLabel="descricao"
                            className={classNames({"p-invalid": isFormFieldInvalid("tipoMovimentacao")}, "w-full")}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                        />
                        {getFormErrorMessage("tipoMovimentacao")}
                    </div>
                    <div className="field col-12 lg:col-3 md:col-4">
                        <label htmlFor="situacaoMovimentacao">Situação</label>
                        <Dropdown
                            id="situacaoMovimentacao"
                            value={formik.values.situacaoMovimentacao}
                            onChange={(e) => {
                                formik.setFieldValue("situacaoMovimentacao", e.target.value);
                                formik.setFieldValue("situacaoMovimentacaoId", e.target.value.id);
                            }}
                            options={situacaoMovimentacao}
                            optionLabel="descricao"
                            className={classNames({"p-invalid": isFormFieldInvalid("situacaoMovimentacao")}, "w-full")}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                        />
                        {getFormErrorMessage("situacaoMovimentacao")}
                    </div>

                    <div className="field col-12 lg:col-3 md:col-4">
                        <label htmlFor="valor">Valor</label>
                        <InputNumber
                            id="valor"
                            value={formik.values.valor}
                            max={9999999.999}
                            onChange={(e) => formik.setFieldValue("valor", e.value)}
                            className={classNames({"p-invalid": isFormFieldInvalid("valor")}, "w-full")}
                            prefix="R$ "
                            minFractionDigits={0}
                            maxFractionDigits={2}
                            disabled={situacaoAtual === 2 || situacaoAtual === 3}
                        />
                        {getFormErrorMessage("valor")}
                    </div>
                    <div className="field col-12">
                        <label htmlFor="descricao">Descrição</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({"p-invalid": isFormFieldInvalid("descricao")}, "w-full")}
                        />
                        {getFormErrorMessage("descricao")}
                    </div>
                </div>
            </Card>)}

        <div className="buttons mt-2">
            <Button label="Salvar" icon="pi pi-save" severity="success" type="submit" onClick={confirmar}
                    loading={loadingSubmit}/>
            <Button
                label="Voltar"
                icon="pi pi-times"
                severity="secondary"
                onClick={() => (window.location.href = "/financeiro/movimentacao")}
            />
        </div>

        <ConfirmDialog/>
    </>);
};

export default MovimentacaoEdicao;
