import axiosInstance from "./baseService.service";

const API_URL = "paciente/";

const pacienteService = {
  resgatarPacientes: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarPacientes");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getPaciente: async (id) => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarPaciente?id=" + id);
      return response;
    } catch (error) {
      throw error;
    }
  },

  createPaciente: async (paciente) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarPaciente", paciente);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  editPaciente: async (paciente) => {
    try {
      paciente.agendamentos = null;
      const response = await axiosInstance.post(API_URL + "alterarPaciente?id=" + paciente.id, paciente);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  gerarRelatorioListagem: async (dto) => {
    try {
      const response = await axiosInstance.post(API_URL + "relatorioListagem", dto, {responseType: "blob"});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  inativar: async (id) => {
    try {
      const response = await axiosInstance.post(API_URL + "inativar?id=" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default pacienteService;
