import perfilService from "../../services/perfil.service";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { useMountEffect } from "primereact/hooks";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import apoioService from "../../services/apoio.service";
import authService from "../../services/auth.service";
import DialogProfissional from "../common/dialogProfissional.component";
import DialogSenha from "./dialogSenha.component";
import PerfilLoading from "./perfilLoading.component";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
});

const PerfilEdicao = ({ mostrarMensagem }) => {
  const [loading, setLoading] = useState(true);

  const [tipoSexo, setTipoSexo] = useState([]);

  const [dialogProfissionalVisible, setDialogProfissionalVisible] = useState(false);
  const [dialogSenhaVisible, setDialogSenhaVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: 0,
      nome: "",
      email: "",
      telefone: null,
      dataNascimento: null,
      userId: 0,
      profissional: null,
    },
    validationSchema,
    onSubmit: (values) => {
      perfilService
        .editPerfil(values)
        .then((result) => {
          mostrarMensagem("Perfil alterado com sucesso!", "success");
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            mostrarMensagem(error.response.data, "error");
          } else {
            mostrarMensagem("Não foi possivel alterar o perfil!", "error");
          }
        });
    },
  });

  useMountEffect(() => {
    apoioService.listaTipoSexo().then((result) => setTipoSexo(result));

    perfilService
      .getPerfil()
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          formik.setValues(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        mostrarMensagem("Houve um problema ao resgatar o Perfil! ", "error");
      });
  }, [mostrarMensagem, formik]);

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      {loading ? (
        <PerfilLoading />
      ) : (
        <>
          <Card
            title={() => (
              <div className="grid">
                <div className="col">
                  <span className="p-card-title">Perfil</span>
                </div>
                <div className="col-fixed">
                  <Button label="Alterar Senha" icon="pi pi-save" onClick={() => setDialogSenhaVisible(true)} size="small" />
                </div>
              </div>
            )}
          >
            <div className="formgrid grid">
              <div className="field col-12 md:col-8">
                <label htmlFor="nome">Nome</label>
                <InputText
                  id="nome"
                  value={formik.values.nome}
                  maxLength={150}
                  onChange={(e) => formik.setFieldValue("nome", e.target.value)}
                  className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
                />
                {getFormErrorMessage("nome")}
              </div>
              <div className="field col-12 lg:col-4 md:col-4">
                <label htmlFor="dataNascimento">Data de Nascimento</label>
                <Calendar
                  id="dataNascimento"
                  value={formik.values.dataNascimento !== null ? new Date(formik.values.dataNascimento) : null}
                  onChange={(e) => {
                    if (e.target.value === "") e.target.value = null;
                    if (e.target.value !== null) {
                      formik.setFieldValue("dataNascimento", new Date(e.value).toISOString());
                    } else {
                      formik.setFieldValue("dataNascimento", null);
                    }
                  }}
                  showIcon
                  className={classNames({ "p-invalid": isFormFieldInvalid("dataNascimento") }, "w-full")}
                  touchUI={window.innerWidth < 768}
                  showOnFocus={false}
                  mask="99/99/9999"
                />
                {getFormErrorMessage("dataNascimento")}
              </div>
              <div className="field col-12 lg:col-3 md:col-6">
                <label htmlFor="sexo">Sexo</label>
                <Dropdown
                  id="sexo"
                  value={formik.values.sexo}
                  onChange={(e) => formik.setFieldValue("sexo", e.target.value)}
                  options={tipoSexo}
                  optionLabel="descricao"
                  placeholder="Selecione o sexo"
                  className={classNames({ "p-invalid": isFormFieldInvalid("sexo") }, "w-full")}
                />
              </div>

              <div className="field col-12 lg:col-3 md:col-6">
                <label htmlFor="telefone">Telefone</label>
                <InputMask
                  id="telefone"
                  value={formik.values.telefone}
                  onChange={(e) => {
                    if (e.target.value === "") e.target.value = null;
                    formik.setFieldValue("telefone", e.target.value);
                  }}
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  className={classNames({ "p-invalid": isFormFieldInvalid("telefone") }, "w-full")}
                />
                {getFormErrorMessage("telefone")}
              </div>
              <div className="field col-12 lg:col-3 md:col-6">
                <label htmlFor="cpf">CPF</label>
                <InputMask
                  id="cpf"
                  value={formik.values.cpf}
                  onChange={(e) => {
                    if (e.target.value === "") e.target.value = null;
                    formik.setFieldValue("cpf", e.target.value);
                  }}
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  className={classNames({ "p-invalid": isFormFieldInvalid("cpf") }, "w-full")}
                />
                {getFormErrorMessage("cpf")}
              </div>
              <div className="field col-12 lg:col-3 md:col-6">
                <label htmlFor="rg">RG</label>
                <InputMask
                  id="rg"
                  value={formik.values.rg}
                  onChange={(e) => {
                    if (e.target.value === "") e.target.value = null;
                    formik.setFieldValue("rg", e.target.value);
                  }}
                  mask="99.999.999-*"
                  placeholder="99.999.999-9"
                  className="w-full"
                />
              </div>
              <div className="field col-12">
                <label htmlFor="profissional">Profissional</label>
                <div className="flex">
                  <div 
                    className="flex-grow-1" 
                    onClick={() => setDialogProfissionalVisible(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    <InputText
                      value={formik.values.profissional?.nome}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldInvalid("profissional", formik),
                        },
                        "w-full"
                      )}
                      readOnly
                    />
                  </div>
                  <Button
                    icon="pi pi-search"
                    onClick={() => setDialogProfissionalVisible(true)}
                    className="flex-none ml-2"
                    visible={authService.checkIfHasPermissao(["ADM_SUPER"])}
                  />
                </div>
                {getFormErrorMessage("profissional", formik)}
              </div>
            </div>
          </Card>
          <div className="buttons mt-2">
            <Button label="Alterar" icon="pi pi-save" severity="success" onClick={formik.handleSubmit} />
          </div>
        </>
      )}

      <DialogProfissional
        dialogProfissionalVisible={dialogProfissionalVisible}
        setDialogProfissionalVisible={setDialogProfissionalVisible}
        onSelectMetodo={(profissional) => {
          formik.setFieldValue("profissional", profissional);
        }}
      />

      <DialogSenha dialogSenhaVisible={dialogSenhaVisible} setDialogSenhaVisible={setDialogSenhaVisible} />
    </>
  );
};

export default PerfilEdicao;
