import axiosInstance from "./baseService.service";

const API_URL = "profissional/";

const profissionalService = {
  pesquisarProfissionais: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarProfissionais");
      return response;
    } catch (error) {
      throw error;
    }
  },

  pesquisarProfissional: async (id) => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarProfissional?id=" + id);
      return response;
    } catch (error) {
      throw error;
    }
  },

  cadastrarProfissional: async (profissional) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarProfissional", profissional);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarProfissional: async (profissional) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarProfissional", profissional);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  inativar: async (id) => {
    try {
      const response = await axiosInstance.post(API_URL + "inativar?id=" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
},
};

export default profissionalService;
