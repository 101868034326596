import profissionalService from "../../services/profissional.service";
import authService from "../../services/auth.service";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

const ProfissionalPesquisa = ({ mostrarMensagem }) => {
  const permissaoCadastro = authService.checkIfHasPermissao(["CAD_PROFISSIONAL"]);
  const [loading, setLoading] = useState(false);
  const [profissionals, setProfissionals] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    pesquisarProfissionais();
  }, []);

  const pesquisarProfissionais = () => {
    setLoading(true);
    profissionalService.pesquisarProfissionais().then((result) => {
      setProfissionals(result.data);
      setLoading(false);
    });
  };

  const accept = (id) => {
    profissionalService.inativar(id).then(() => {
      pesquisarProfissionais();
      mostrarMensagem("Paciente inativado com sucesso!", "success");
    });
  }

  const inativarProfissional = (profissional) => {
    confirmDialog({
      message: 'Deseja realmente inativar o profissional ' + profissional.nome + '?',
      header: 'Inativar Profissional',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => accept(profissional.id)
    });
  }

  return (
    <>
      <Card title="Profissionais">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => (window.location.href = "/profissional/cadastro")}
              disabled={!permissaoCadastro}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar..." className="w-full" />
              </span>

              <Button icon="pi pi-refresh" onClick={() => pesquisarProfissionais()} className="flex-none" severity="info" />
            </div>
          </div>
        </div>
        <DataTable
          value={profissionals}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          filters={filters}
          globalFilterFields={["nome"]}
          loading={loading}
        >
          <Column field="cargo.nome" header="Cargo"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="telefone" header="Telefone"></Column>
          <Column
            field="edit"
            headerStyle={{ width: "1rem" }}
            body={(profissional) => (
              <Button
                icon={permissaoCadastro ? "pi pi-pencil" : "pi pi-eye"}
                size="small"
                onClick={() => (window.location.href = `/profissional/cadastro?id=${profissional.id}`)}
              />
            )}
          />
          <Column hidden={!authService.checkIfHasPermissao(["ADMIN_SUPER"])}
                  style={{width: "1rem"}}
                  body={(profissional) => (<Button
                      icon={"pi pi-trash"}
                      size="small"
                      severity="danger"
                      onClick={() => inativarProfissional(profissional)}
                  />)}
          />
        </DataTable>
      </Card>
      <ConfirmDialog/>
    </>
  );
};

export default ProfissionalPesquisa;
