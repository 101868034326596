import agendamentoService from "../../services/agendamento.service";
import avisoService from "../../services/aviso.service";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import authService from "../../services/auth.service";
import { formatarData } from "../../util/FormatarData";
import DialogAgendamentoCancelar from "./dialogAgendamentoCancelar.component";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import DialogAgendamentoAlterar from "./dialogAgendamentoAlterar.component";
import DialogDocumentoComponent from "./dialogDocumento.component";

const DialogAgendamentoInfo = ({
  dialogAgendamentoVisible,
  setDialogAgendamentoVisible,
  atualizar,
  agendamento,
  setAgendamento,
  mostrarMensagem,
}) => {
  const permissaoPesquisaPaciente = authService.checkIfHasPermissao(["PES_PACIENTE"]);
  const permissaoPesquisaProfissional = authService.checkIfHasPermissao(["PES_PROFISSIONAL"]);
  const permissaoCadastroAgendamento = authService.checkIfHasPermissao(["CAD_AGENDAMENTO"]);
  const permissaoPesquisaDocumento = authService.checkIfHasPermissao(["PES_AGENDAMENTO_DOCUMENTO"]);

  const [situacaoCancelamento, setSituacaoCancelamento] = useState({});
  const [dialogCancelarVisible, setDialogCancelarVisible] = useState(false);
  const [dialogAlterarVisible, setDialogAlterarVisible] = useState(false);
  const [dialogDocumentoVisible, setDialogDocumentoVisible] = useState(false);

  const enviarConfirmacaoPresenca = (rowData) => {
    avisoService.gerarAvisoConfirmacaoAgendamento(rowData.id).then((urlMensagem) => {
      window.open(urlMensagem, "_blank");
      setAgendamento({ ...rowData, avisoConfirmacaoEnviado: true });
    });
  };

  const situacoes = [
    {
      id: 1,
      label: "Agendado",
      icon: "pi pi-calendar",
    },
    {
      id: 2,
      label: "Atendimento realizado",
      icon: "pi pi-thumbs-up",
    },
    {
      id: 3,
      label: "Paciente ausente",
      icon: "pi pi-times",
    },
    {
      id: 4,
      label: "Cancelado pelo paciente",
      icon: "pi pi-ban",
    },
    {
      id: 5,
      label: "Cancelado pelo profissional",
      icon: "pi pi-ban",
    },
  ];

  const confirmarExclusao = () => {
    confirmDialog({
      message: "Deseja realmente excluir o agendamento?",
      header: "Excluir Agendamento",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => {
        agendamentoService.excluir(agendamento.id).then(() => {
          atualizar();
          setDialogAgendamentoVisible(false);
          mostrarMensagem("Agendamento excluído", "success", null, true);
        });
      },
    });
  };

  return (
    <>
      <Dialog
        id="dialogAgendamentoInfo"
        visible={dialogAgendamentoVisible}
        style={{ width: "90vw" }}
        onHide={() => setDialogAgendamentoVisible(false)}
        header={
          <div className="grid mr-1">
            <div className="col">
              <span className="p-card-title">Agendamentos</span>
            </div>
            <div className="col-fixed buttons">
              <Button
                visible={agendamento.situacaoAgendamentoId === 1 && agendamento.pacienteTelefone !== null}
                icon="pi pi-send"
                severity={agendamento.avisoConfirmacaoEnviado ? "secondary" : "info"}
                size="small"
                onClick={() => enviarConfirmacaoPresenca(agendamento)}
              />
              <Button
                icon="pi pi-pencil"
                size="small"
                onClick={() => setDialogAlterarVisible(true)}
                visible={authService.checkIfHasPermissao(["ALT_AGENDAMENTO"])}
              />
              <Button
                icon="pi pi-trash"
                severity="danger"
                size="small"
                onClick={() => confirmarExclusao()}
                visible={authService.checkIfHasPermissaoIgnoraAdmin(["EXC_AGENDAMENTO"])}
              />
              <Button
                  icon="pi pi-clipboard"
                  size="small"
                  onClick={() => setDialogDocumentoVisible(true)}
                  visible={permissaoPesquisaDocumento}
              />
            </div>
          </div>
        }
      >
        <div className="grid">
          <div className="field col-12 lg:col-6 md:col-6">
            <label htmlFor="paciente">Paciente</label>
            <div className="flex">
              <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {permissaoPesquisaPaciente && (
                  <a href={"/paciente/cadastro?id=" + agendamento.pacienteId}>{agendamento.pacienteNome}</a>
                )}
                {!permissaoPesquisaPaciente && <span>{agendamento.pacienteNome}</span>}
              </span>
            </div>
          </div>
          <div className="field col-12 lg:col-6 md:col-6">
            <label htmlFor="profissional">Profissional</label>
            <div className="flex">
              <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {permissaoPesquisaProfissional && (
                  <a href={"/profissional/cadastro?id=" + agendamento.profissionalId}>{agendamento.profissionalNome}</a>
                )}
                {!permissaoPesquisaProfissional && <span>{agendamento.profissionalNome}</span>}
              </span>
            </div>
          </div>
          <div className="field col-12 lg:col-6 md:col-6">
            <label htmlFor="dataAgendamento">Data</label>
            <div className="flex">
              <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                {formatarData(agendamento.dataAgendamento)} - {agendamento.horaInicial} até {agendamento.horaFinal}
              </span>
            </div>
          </div>
          {agendamento.salaId && (
            <div className="field col-12 lg:col-6 md:col-6">
              <label htmlFor="sala">Sala</label>
              <div className="flex">
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>{agendamento.salaNome}</span>
              </div>
            </div>
          )}
          <div className="field col-12">
            <label htmlFor="situacao">Situação</label>
            <div className="flex">
              {permissaoCadastroAgendamento && (
                <Dropdown
                  value={agendamento.situacaoAgendamentoId}
                  onChange={(e) => {
                    setAgendamento({ ...agendamento, situacaoAgendamentoId: e.value });
                    if (e.value === 1 || e.value === 2) {
                      agendamentoService
                        .alterarSituacao({
                          agendamentoId: agendamento.id,
                          situacaoAgendamentoId: e.value,
                        })
                        .then(() => {
                          atualizar();
                          setAgendamento({
                            ...agendamento,
                            situacaoAgendamentoId: e.value,
                            motivoCancelamento: null,
                          });
                          mostrarMensagem("Agendamento atualizado", "success", null, true);
                        });
                    } else {
                      setSituacaoCancelamento(e.value);
                      setDialogCancelarVisible(true);
                    }
                  }}
                  optionValue="id"
                  options={situacoes}
                  optionLabel="label"
                  valueTemplate={(item) => itemTemplate(item)}
                  itemTemplate={(item) => itemTemplate(item)}
                />
              )}
              {!permissaoCadastroAgendamento && (
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  {itemTemplate(situacoes.find((s) => s.id === agendamento.situacaoAgendamentoId))}
                </span>
              )}
            </div>
          </div>
          {agendamento.motivoCancelamento && (
            <div className="field col-12 lg:col-6 md:col-6">
              <label htmlFor="sala">Motivo do Cancelamento</label>
              <div className="flex">
                <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>{agendamento.motivoCancelamento}</span>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <DialogAgendamentoCancelar
        dialogVisible={dialogCancelarVisible}
        setDialogVisible={setDialogCancelarVisible}
        agendamento={agendamento}
        onSaveMetodo={(values) => {
          agendamentoService
            .alterarSituacao({ agendamentoId: agendamento.id, situacaoAgendamentoId: situacaoCancelamento, ...values })
            .then(() => {
              atualizar();
              setAgendamento({
                ...agendamento,
                situacaoAgendamentoId: situacaoCancelamento,
                motivoCancelamento: values.motivoCancelamento,
              });
              setDialogCancelarVisible(false);

              mostrarMensagem("Agendamento cancelado com sucesso", "success", null, true);
            });
        }}
      />

      <DialogAgendamentoAlterar
        dialogVisible={dialogAlterarVisible}
        setDialogVisible={setDialogAlterarVisible}
        agendamento={agendamento}
        mostrarMensagem={mostrarMensagem}
        onSaveMetodo={(values) => {
          atualizar();
          const dataAgendamento = new Date(values.dataAgendamento);
          setAgendamento({
            ...agendamento,
            dataAgendamento: dataAgendamento.toISOString(),
            horaInicial: values.horaInicial,
            horaFinal: values.horaFinal,
            salaId: values.salaId,
            salaNome: values.sala?.nome,
            valor: values.valor,
          });
          setDialogAlterarVisible(false);
        }}
      />

      {permissaoPesquisaDocumento && <DialogDocumentoComponent
          dialogVisible={dialogDocumentoVisible}
          setDialogVisible={setDialogDocumentoVisible}
          agendamento={agendamento}
          mostrarMensagem={mostrarMensagem}
      />}

      <ConfirmDialog />
    </>
  );

  function itemTemplate(item) {
    if (!item) return;
    return (
      <div
        style={{
          color:
            item.id === 1
              ? "#0ea5e9"
              : item.id === 2
              ? "#22c55e"
              : item.id === 3
              ? "#f97316"
              : item.id === 4 || item.id === 5
              ? "#ef4444"
              : "#64748b",
        }}
      >
        <i className={"mr-1 " + item.icon} />
        {item.label}
      </div>
    );
  }
};

export default DialogAgendamentoInfo;
