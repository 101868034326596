import agendamentoService from "../../services/agendamento.service";

import DialogAgendamentoCadastrar from "./dialogAgendamentoCadastrar.component";

import ptLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useMountEffect } from "primereact/hooks";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import authService from "../../services/auth.service";
import DialogAgendamentoInfo from "./dialogAgendamentoInfo.component";

const AgendamentoPesquisa = ({ mostrarMensagem }) => {
  const permissaoCadastroAgendamento = authService.checkIfHasPermissao(["CAD_AGENDAMENTO"]);

  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [dialogAgendamentoVisible, setDialogAgendamentoVisible] = useState(false);
  const [dialogInfoVisible, setDialogInfoVisible] = useState(false);
  const [agendamento, setAgendamento] = useState({});

  const [calendar, setCalendar] = useState(null);

  useMountEffect(() => {
    document.querySelector(".fc-timeGridWeek-button").classList.add("fc-button-active");
  });

  const handleDateClick = (arg) => {
    if (!permissaoCadastroAgendamento) return;
    const data = arg.dateStr.split("T")[0];
    const dataFormatada = {
      ano: data.split("-")[0],
      mes: data.split("-")[1],
      dia: data.split("-")[2],
    };
    var hora = null;
    var horaFinal = null;
    if (arg.dateStr.includes("T")) {
      hora = arg.dateStr.split("T")[1].split(":")[0] + ":" + arg.dateStr.split("T")[1].split(":")[1];
      horaFinal = parseInt(arg.dateStr.split("T")[1].split(":")[0]) + 1;
      horaFinal = (horaFinal >= 10 ? horaFinal : "0" + horaFinal) + ":" + arg.dateStr.split("T")[1].split(":")[1];
    }
    setDataSelecionada({
      horaInicial: hora,
      horaFinal: horaFinal,
      data: dataFormatada,
    });
    setDialogAgendamentoVisible(true);
  };

  const handleEventClick = (clickInfo) => {
    setAgendamento(clickInfo.event.extendedProps.data);
    setTimeout(() => {
      setDialogInfoVisible(true);
    }, 100);
  };

  return (
    <>
      <Card
        title={
          <div className="grid">
            <div className="field col">Agendamentos</div>
            <div className="field col-fixed">
              <Button
                label="Agendar Sessão"
                icon="pi pi-plus"
                severity="success"
                onClick={() => setDialogAgendamentoVisible(true)}
                size="small"
                disabled={!permissaoCadastroAgendamento}
              />
            </div>
          </div>
        }
      >
        <FullCalendar
          ref={setCalendar}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            start: "prev next today",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="timeGridWeek"
          events={eventosFetch}
          selectable={true}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          locale={ptLocale}
          height="auto"
          navLinks={true}
          eventContent={(e) => renderEventContent}
          dayMaxEventRows={3}
          eventMaxStack={3}
          allDaySlot={false}
          slotDuration="00:30:00"
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          slotMinTime="06:00:00"
        />
      </Card>

      <DialogAgendamentoCadastrar
        dialogAgendamentoVisible={dialogAgendamentoVisible}
        setDialogAgendamentoVisible={setDialogAgendamentoVisible}
        dataSelecionada={dataSelecionada}
        mostrarMensagem={mostrarMensagem}
        onSaveMetodo={(dataAgendamento) => {
          mostrarMensagem("Agendamento salvo com sucesso!", "success", null, true);
          const ano = dataAgendamento.getFullYear();
          const mes = String(dataAgendamento.getMonth() + 1).padStart(2, "0");
          const dia = String(dataAgendamento.getDate()).padStart(2, "0");
          const dataFormatada = `${ano}-${mes}-${dia}`;
          calendar.getApi().gotoDate(dataFormatada);

          if (
            calendar.getApi().view.type === "dayGridMonth" &&
            (dataAgendamento.getMonth() !== calendar.getApi().getDate().getMonth() ||
              dataAgendamento.getFullYear() !== calendar.getApi().getDate().getFullYear())
          )
            return;

          if (
            calendar.getApi().view.type === "timeGridWeek" &&
            (dataAgendamento.getMonth() !== calendar.getApi().getDate().getMonth() ||
              dataAgendamento.getFullYear() !== calendar.getApi().getDate().getFullYear() ||
              diferencaEntreDatas(dataAgendamento, calendar.getApi().getDate()) > 7)
          )
            return;

          if (
            calendar.getApi().view.type === "timeGridDay" &&
            (dataAgendamento.getDate() !== calendar.getApi().getDate() ||
              dataAgendamento.getMonth() !== calendar.getApi().getDate().getMonth() ||
              dataAgendamento.getFullYear() !== calendar.getApi().getDate().getFullYear())
          )
            return;

          calendar.getApi().refetchEvents();
        }}
      />

      <DialogAgendamentoInfo
        dialogAgendamentoVisible={dialogInfoVisible}
        setDialogAgendamentoVisible={setDialogInfoVisible}
        atualizar={() => calendar.getApi().refetchEvents()}
        agendamento={agendamento}
        setAgendamento={setAgendamento}
        mostrarMensagem={mostrarMensagem}
      />
    </>
  );
};

function eventosFetch(info, successCallback, failureCallback) {
  var dataInicial = info.startStr.split("T")[0];
  dataInicial = dataInicial.split("-")[2] + "/" + dataInicial.split("-")[1] + "/" + dataInicial.split("-")[0];
  var dataFinal = info.endStr.split("T")[0];
  dataFinal = dataFinal.split("-")[2] + "/" + dataFinal.split("-")[1] + "/" + dataFinal.split("-")[0];
  agendamentoService
    .pesquisarAgendamentosPeriodo(dataInicial, dataFinal)
    .then((result) => {
      let events = [];
      result.forEach((agendamento) => {
        const dataAgendamento = agendamento.dataAgendamento.split("T")[0];
        const horaInicial = agendamento.horaInicial;
        const horaFinal = agendamento.horaFinal;
        events.push({
          id: agendamento.id,
          start: dataAgendamento + "T" + horaInicial + ":00",
          end: dataAgendamento + "T" + horaFinal + ":00",
          data: agendamento,
          backgroundColor:
            agendamento.situacaoAgendamentoId === 1
              ? "#0085c1"
              : agendamento.situacaoAgendamentoId === 2
              ? "#00752b"
              : agendamento.situacaoAgendamentoId === 3
              ? "#cbb000"
              : agendamento.situacaoAgendamentoId === 4 || agendamento.situacaoAgendamentoId === 5
              ? "#c32626"
              : "#64748b",
          borderColor:
            agendamento.situacaoAgendamentoId === 1
              ? "#0085c1"
              : agendamento.situacaoAgendamentoId === 2
              ? "#00752b"
              : agendamento.situacaoAgendamentoId === 3
              ? "#cbb000"
              : agendamento.situacaoAgendamentoId === 4 || agendamento.situacaoAgendamentoId === 5
              ? "#c32626"
              : "#64748b",
        });
      });
      return successCallback(events);
    })
    .catch((error) => {
      return failureCallback(error);
    });
}

function renderEventContent(eventInfo) {
  const { horaInicial, horaFinal, profissionalNome, pacienteNome, salaNome } = eventInfo.event.extendedProps.data;
  const [startHour, startMin] = horaInicial.split(":").map(Number);
  const [endHour, endMin] = horaFinal.split(":").map(Number);

  var diffMin = (endHour - startHour) * 60 + (endMin - startMin);
  if (eventInfo.view.type === "dayGridMonth") diffMin = 0;
  const baseStyle = {
    marginTop: "6px",
    marginBottom: "6px",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: diffMin > 30 ? "column" : "row",
        fontSize: "10px",
        overflow: "hidden",
        lineHeight: "0px",
        whiteSpace: "nowrap",
        marginLeft: "1px",
        marginTop: "3px",
        userSelect: "none",
        cursor: "pointer",
      }}
      title={
        pacienteNome +
        " | " +
        profissionalNome +
        " | " +
        horaInicial +
        " - " +
        horaFinal +
        (salaNome ? " | " + salaNome : "") +
        " | " +
        eventInfo.event.extendedProps.data.situacaoAgendamentoDescricao
      }
    >
      <p style={baseStyle}>
        <strong>{pacienteNome}</strong>
      </p>
      <p
        style={{
          ...baseStyle,
          marginLeft: diffMin > 30 ? "0px" : "3px",
        }}
      >
        {diffMin <= 30 && " | "}
        <strong>{profissionalNome}</strong>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: diffMin > 60 ? "column" : "row",
          marginLeft: diffMin > 30 ? "0px" : "3px",
        }}
      >
        <p style={baseStyle}>
          {diffMin <= 30 && " | "}
          {`${horaInicial} - ${horaFinal}`}
        </p>
        <p
          style={{
            ...baseStyle,
            marginLeft: diffMin > 60 ? "0px" : "3px",
          }}
        >
          {salaNome && diffMin <= 60 && " | "}
          {salaNome || ""}
        </p>
      </div>
      <div className={classNames("icon-confirmacao-enviada", diffMin > 0 ? "" : "hidden")}>
        <span
          className={classNames(
            "pi",
            eventInfo.event.extendedProps.data.situacaoAgendamentoId === 1
              ? "pi-calendar"
              : eventInfo.event.extendedProps.data.situacaoAgendamentoId === 2
              ? "pi-thumbs-up"
              : eventInfo.event.extendedProps.data.situacaoAgendamentoId === 3
              ? "pi-times"
              : eventInfo.event.extendedProps.data.situacaoAgendamentoId === 4 ||
                eventInfo.event.extendedProps.data.situacaoAgendamentoId === 5
              ? "pi-ban"
              : ""
          )}
        ></span>
      </div>
    </div>
  );
}

function diferencaEntreDatas(date1, date2) {
  var diff = date1 - date2;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
}

export default AgendamentoPesquisa;
