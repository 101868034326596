import React from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

const Index = ({ mostrarMensagem }) => {
  return (
    <div className="landing-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="grid">
          <div className="col-12 md:col-6 flex flex-column justify-content-center">
            <h1 className="text-6xl font-bold mb-2">PsiQ</h1>
            <h2 className="text-4xl font-medium mb-4">Gestão de Consultórios Psicológicos</h2>
            <p className="mb-4 text-lg line-height-3">
              A solução completa para profissionais da saúde mental gerenciarem suas consultas,
              pacientes e finanças de forma eficiente e segura.
            </p>
            <div className="flex">
              <Button 
                label="Comece Agora" 
                icon="pi pi-user-plus" 
                className="mr-3 p-button-lg"
                onClick={() => window.location.href = "/registrar"} 
              />
              <Button 
                label="Saiba Mais" 
                icon="pi pi-info-circle" 
                className="p-button-outlined p-button-lg"
                onClick={() => document.getElementById('features').scrollIntoView({ behavior: 'smooth' })}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 flex align-items-center justify-content-center">
            <img 
              src="https://img.freepik.com/free-vector/psychologist-concept-illustration_114360-2141.jpg" 
              alt="Psychology Illustration" 
              style={{ maxWidth: '100%', height: 'auto' }}
              className="hero-image"
            />
          </div>
        </div>
      </section>

      <Divider />

      {/* Features Section */}
      <section id="features" className="features-section py-6">
        <h2 className="text-center text-4xl font-medium mb-5">Recursos Principais</h2>
        <div className="grid">
          <div className="col-12 md:col-4 mb-4">
            <Card className="h-full">
              <div className="text-center mb-3">
                <i className="pi pi-calendar text-5xl text-primary"></i>
              </div>
              <h3 className="text-xl font-medium text-center mb-3">Agendamentos</h3>
              <p className="text-center line-height-3">
                Gerencie sua agenda com facilidade, evite conflitos de horários e envie lembretes automáticos aos seus pacientes.
              </p>
            </Card>
          </div>
          <div className="col-12 md:col-4 mb-4">
            <Card className="h-full">
              <div className="text-center mb-3">
                <i className="pi pi-users text-5xl text-primary"></i>
              </div>
              <h3 className="text-xl font-medium text-center mb-3">Prontuários Eletrônicos</h3>
              <p className="text-center line-height-3">
                Mantenha os registros de seus pacientes organizados, seguros e facilmente acessíveis quando necessário.
              </p>
            </Card>
          </div>
          <div className="col-12 md:col-4 mb-4">
            <Card className="h-full">
              <div className="text-center mb-3">
                <i className="pi pi-dollar text-5xl text-primary"></i>
              </div>
              <h3 className="text-xl font-medium text-center mb-3">Gestão Financeira</h3>
              <p className="text-center line-height-3">
                Controle pagamentos, gere relatórios financeiros e acompanhe suas receitas em tempo real.
              </p>
            </Card>
          </div>
        </div>
      </section>

      <Divider />

      {/* CTA Section */}
      <section className="cta-section py-6 text-center">
        <h2 className="text-4xl font-medium mb-3">Pronto para simplificar a gestão do seu consultório?</h2>
        <p className="mb-4 text-lg line-height-3 mx-auto" style={{ maxWidth: '700px' }}>
          Junte-se a centenas de profissionais que já estão usando o PsiQ para otimizar seu tempo e melhorar o atendimento aos pacientes.
        </p>
        <Button 
          label="Criar Conta Gratuita" 
          icon="pi pi-user-plus" 
          className="p-button-lg"
          onClick={() => window.location.href = "/registrar"} 
        />
      </section>
    </div>
  );
};

export default Index;
