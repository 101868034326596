import axiosInstance from "./baseService.service";

const API_URL = "/apoio/";

const apoioService = {
  listaSituacaoAgendamento: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "situacaoAgendamento");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoContaFinanceiro: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoContaFinanceiro");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaSituacaoMovimentacaoFinanceiro: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "situacaoMovimentacaoFinanceiro");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoMovimentacaoFinanceiro: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoMovimentacaoFinanceiro");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoMensagem: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoMensagem");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoEscolaridade: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoEscolaridade");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoEstadoCivil: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoEstadoCivil");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaTipoSexo: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "tipoSexo");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  listaPermissao: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "permissao");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apoioService;
