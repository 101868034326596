import axiosInstance from "./baseService.service";

const API_URL = "contaFinanceiro/";

const contaService = {
    resgatarContas: async () => {
        try {
            const response = await axiosInstance.get(API_URL + "pesquisarContas");
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    pesquisarConta: async (id) => {
        try {
            const response = await axiosInstance.get(API_URL + "pesquisarConta?id=" + id);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    cadastrarConta: async (conta) => {
        try {
            const response = await axiosInstance.post(API_URL + "cadastrarConta", conta);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    alterarConta: async (conta) => {
        try {
            const response = await axiosInstance.post(API_URL + "alterarConta", conta);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default contaService;
