import { Card } from 'primereact/card';
import React, { useState } from 'react';
import DialogProfissional from '../common/dialogProfissional.component';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import comissaoService from '../../services/comissao.service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMediaQuery } from 'react-responsive';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';

const validationSchema = Yup.object().shape({
    profissional: Yup.object().required('Profissional é obrigatório.'),
    dataInicial: Yup.string().required('Data de Inicial é obrigatória.'),
    dataFinal: Yup.string().required('Data de Final é obrigatória.')
});

const ComissaoPesquisa = ({ mostrarMensagem }) => {

    const [dialogProfissionalVisible, setDialogProfissionalVisible] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [comissoes, setComissoes] = useState([]);
    const [comissaoRetorno, setComissaoRetorno] = useState([]);

    const [isResultEmpty, setIsResultEmpty] = useState(false)

    const handleMediaQueryChange = (matches) => {
        if (matches) {
            setIsDetailOpen(true);
        }
    };

    const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMd = useMediaQuery({ minWidth: 768 }, undefined, handleMediaQueryChange);

    const [isDetailOpen, setIsDetailOpen] = useState(true);

    const formik = useFormik({
        initialValues: {
            id: '', profissional: null, profissionalId: null, dataInicio: null, horaInicial: null
        }, validationSchema, validate: (values) => {
            if (values.presencial && !values.sala) return { sala: 'Sala é obrigatória.' };
        }, onSubmit: (values) => {
            setLoadingSubmit(true);
            gerarComissao();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
    };

    const gerarComissao = () => {
        comissaoService.gerarComissao({
            profissionalId: formik.values.profissionalId,
            dataInicial: formik.values.dataInicial,
            dataFinal: formik.values.dataFinal
        }).then((result) => {

            if (result.atendimentos.length === 0) {
                setLoadingSubmit(false);
                setIsResultEmpty(true)
                return
            }
            setComissaoRetorno(result);

            let listaAtendimentos = [];

            result.atendimentos.forEach((atendimento) => {
                listaAtendimentos.push({
                    comissaoAtendimentoId: atendimento.comissaoAtendimentoId,
                    agendamentoId: atendimento.agendamentoId,
                    nomePaciente: atendimento.nomePaciente,
                    dataAtendimento: formatarData(atendimento.dataAtendimento),
                    sala: atendimento.sala,
                    horario: atendimento.horaInicial + ' - ' + atendimento.horaFinal,
                    valor: atendimento.valor,
                    comissaoPorcentagem: 20,
                    comissaoValor: atendimento.valor * 0.2
                });
            });

            setComissoes({
                comissaoId: result.comissaoId,
                profissionalId: result.profissionalId,
                nomeProfissional: result.nomeProfissional,
                dataInicial: formatarData(result.dataInicial),
                dataFinal: formatarData(result.dataFinal),
                valorTotal: result.valorTotal,
                comissaoTotal: result.comissaoTotal,
                atendimentos: listaAtendimentos
            });

            setLoadingSubmit(false);
        }).catch((error) => {
            mostrarMensagem(error.response, 'error', 'Erro', true);
            setLoadingSubmit(false);
        });
    };

    const formatarData = (data) => {
        const [ano, mes, dia] = data.split('T')[0].split('-');
        return `${dia}/${mes}/${ano}`;
    };

    const converterValor = (saldo) => {
        return ('R$ ' + saldo.toLocaleString('pt-br', {
            minimumFractionDigits: 2, maximumFractionDigits: 2
        }));
    };

    const exportPdf = () => {
        comissaoService.gerarRelatorio(comissaoRetorno).then(response => {
            const file = new Blob([response], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank")
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            let atendimentos = [...comissoes.atendimentos];

            atendimentos.push({
                nomePaciente: 'Total',
                dataAtendimento: '',
                sala: '',
                horario: '',
                valor: '',
                comissaoPorcentagem: '',
                comissaoValor: ''
            });

            let worksheet = xlsx.utils.json_to_sheet(atendimentos);
            worksheet['A1'].v = 'Paciente';
            worksheet['B1'].v = 'Data';
            worksheet['C1'].v = 'Sala';
            worksheet['D1'].v = 'Horário';
            worksheet['E1'].v = 'Valor';
            worksheet['F1'].v = 'Comissão %';
            worksheet['G1'].v = 'Comissão Valor';

            for (let i = 2; i <= atendimentos.length; i++) {
                worksheet['G' + i].f = '=E' + i + '*F' + i + '%';
            }
            worksheet['E' + (atendimentos.length + 1)].f = '=SUM(E2:E' + atendimentos.length + ')';
            worksheet['G' + (atendimentos.length + 1)].f = '=SUM(G2:G' + atendimentos.length + ')';

            for (let i = 2; i <= atendimentos.length + 1; i++) {
                worksheet['E' + i].z = '#,##0.00';
                worksheet['G' + i].z = '#,##0.00';
            }

            const wscols = [{ wch: 25 }, { wch: 10 }, { wch: 15 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 }];
            worksheet['!cols'] = wscols;

            worksheet['!rows'] = [{
                hpt: 20, hpx: 20,
            }];


            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx', type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'comissao_' + comissoes.nomeProfissional);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const calculateTotalComission = (appointments) => {
        let total = 0;

        appointments.forEach(instance => {
            total += instance.comissaoValor;
        });

        return total;
    }

    const updateComissionValue = (value, appointment) => {
        const updatedAppointments = comissoes.atendimentos.map(instance => {
            if (appointment === instance) {
                instance.comissaoPorcentagem = Number(value);
                instance.comissaoValor = instance.valor * (Number(value) / 100);
                return instance;
            } else {
                return instance;
            }
        });

        const totalComission = calculateTotalComission(updatedAppointments);

        setComissoes({
            ...comissoes,
            atendimentos: updatedAppointments,
            comissaoTotal: totalComission
        });
    }

    return (
        <section style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 84px)',
            maxHeight: isMd ? 'calc(100vh - 84px)' : 'auto',
            gap: '8px'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: isMd ? 'row' : 'column',
                gap: '8px'
            }}>
                <Card title="Gerar Comissão" style={{
                    width: isMd ? '50%' : 'initial'
                }}>
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="profissional">Profissional</label>
                            <div className="flex" onClick={() => setDialogProfissionalVisible(true)}>
                                <InputText
                                    value={formik.values.profissional?.nome}
                                    className={classNames({
                                        'p-invalid': isFormFieldInvalid('profissional')
                                    }, 'w-full flex-grow-1 mr-2')}
                                    style={{ cursor: 'pointer' }}
                                    readOnly
                                />
                                <Button icon="pi pi-search" className="flex-none" />
                            </div>
                            {getFormErrorMessage('profissional')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dataAgendamento">Data Inicial</label>
                            <Calendar
                                id="dataAgendamento"
                                value={formik.values.dataInicial}
                                onChange={(e) => formik.setFieldValue('dataInicial', e.value)}
                                dateFormat="dd/mm/yy"
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('dataInicial')
                                }, 'w-full')}
                                showIcon
                                touchUI={window.innerWidth < 768}
                                showOnFocus={false}
                                mask="99/99/9999"
                            />
                            {getFormErrorMessage('dataInicial')}
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dataFinal">Data Final</label>
                            <Calendar
                                id="dataFinal"
                                value={formik.values.dataFinal}
                                onChange={(e) => formik.setFieldValue('dataFinal', e.value)}
                                dateFormat="dd/mm/yy"
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('dataFinal')
                                }, 'w-full')}
                                showIcon
                                touchUI={window.innerWidth < 768}
                                showOnFocus={false}
                                mask="99/99/9999"
                            />
                            {getFormErrorMessage('dataFinal')}
                        </div>
                    </div>
                    <div className="buttons mt-2">
                        <Button
                            label="Gerar Comissão"
                            icon="pi pi-save"
                            severity="success"
                            onClick={() => formik.handleSubmit()}
                            loading={loadingSubmit}
                            type="submit"
                        />
                    </div>
                </Card>
                <div className='p-card p-component' style={{
                    display: comissoes.atendimentos ? 'block' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMd ? '12px' : '32px',
                    width: isMd ? '50%' : 'initial'
                }}>
                    {
                        comissoes.atendimentos ?
                            (
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <h2 style={{
                                            display: 'flex',
                                            gap: '8px',
                                            alignItems: 'center',
                                            flexShrink: 0,
                                            margin: 0
                                        }}>
                                            <span>Comissões</span>
                                            <i className='pi pi-book'></i>
                                        </h2>
                                        {
                                            isMd ? null : (
                                                <Button onClick={() => {
                                                    setIsDetailOpen(!isDetailOpen);
                                                }} icon={`pi pi-chevron-${isDetailOpen ? 'up' : 'down'}`} size='small' />
                                            )
                                        }
                                    </div>
                                    {
                                        isDetailOpen && <Divider />
                                    }
                                    <div hidden={!isDetailOpen}>
                                        <h3 style={{
                                            fontWeight: 'medium'
                                        }}>{comissoes.nomeProfissional}</h3>
                                        <h3>{comissoes.dataInicial} - {comissoes.dataFinal}</h3>
                                        <p>Valor Total: {converterValor(comissoes.valorTotal)}</p>
                                        <p>Comissão Total: {converterValor(comissoes.comissaoTotal)}</p>
                                    </div>
                                </div>
                            ) :
                            (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    opacity: 0.9
                                }}>
                                    <i className='pi pi-book' style={{
                                        fontSize: '64px'
                                    }}></i>
                                    <h2 style={{
                                        margin: 0
                                    }}>Comissões</h2>
                                    <p style={{
                                        margin: 0,
                                        width: isLg ? '45%' : '60%',
                                        textAlign: 'center'
                                    }}>Ao gerar comissão uma lista que contempla o período escolhido será apresentada logo abaixo</p>
                                </div>
                            )
                    }
                </div>
            </div>

            <Card hidden={!comissoes.atendimentos} style={{
                flexGrow: 1
            }}>
                <DataTable value={comissoes.atendimentos} className="p-datatable-sm">
                    <Column field="nomePaciente" header="Paciente" />
                    <Column field="dataAtendimento" header="Data" />
                    <Column field="sala" header="Sala" />
                    <Column field="horario" header="Horário" />
                    <Column body={(field) => {
                        return converterValor(field.valor);
                    }} header="Valor" />
                    <Column field="comissaoPorcentagem" body={(field) => {
                        return <InputText value={field.comissaoPorcentagem} onChange={comission => {
                            updateComissionValue(comission.target.value, field);
                        }} />;
                    }} header="%" />
                    <Column body={(field) => {
                        return converterValor(field.comissaoValor);
                    }} header="Comissão" />
                </DataTable>
                <div className={'buttons mt-2'}>
                    <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel}
                            data-pr-tooltip="XLS" />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                            data-pr-tooltip="PDF" />
                </div>
            </Card>


            <DialogProfissional
                dialogProfissionalVisible={dialogProfissionalVisible}
                setDialogProfissionalVisible={setDialogProfissionalVisible}
                onSelectMetodo={(profissional) => {
                    formik.setFieldValue('profissional', profissional);
                    formik.setFieldValue('profissionalId', profissional.id);
                }}
            />
            <Dialog
                header="Sem agendamentos"
                visible={isResultEmpty}
                onHide={() => {if (!isResultEmpty) return; setIsResultEmpty(false); }}
            >
                <p>Não foram encontrados agendamentos para o período selecionado.</p>
            </Dialog>
        </section>
    );
};

export default ComissaoPesquisa;
