import pacienteService from "../../services/paciente.service";
import authService from "../../services/auth.service";

import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";

const PacientePesquisa = ({mostrarMensagem}) => {
    const permissaoCadastro = authService.checkIfHasPermissao(["CAD_PACIENTE"]);
    const [loading, setLoading] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = {...filters};

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {
        pesquisarPacientes();
    }, []);

    const pesquisarPacientes = () => {
        setLoading(true);
        pacienteService.resgatarPacientes().then((result) => {
            setPacientes(result);
            setLoading(false);
        });
    };

    const accept = (id) => {
        pacienteService.inativar(id).then(() => {
            pesquisarPacientes();
            mostrarMensagem("Paciente inativado com sucesso!", "success");
        });
    }

    const inativarPaciente = (paciente) => {
        confirmDialog({
            message: 'Deseja realmente inativar o paciente ' + paciente.nome + '?',
            header: 'Inativar Paciente',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => accept(paciente.id)
        });
    }

    const exportarPdf = () => {
        pacienteService.gerarRelatorioListagem().then(response => {
            const file = new Blob([response], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank")
        });
    };

    return (
        <>
            <Card title={
                <div className="grid">
                    <div
                        className="field col">
                        Pacientes
                    </div>
                    <div className="field col-fixed">
                        <Button type="button" icon="pi pi-file-pdf" severity="warning" onClick={exportarPdf}
                                data-pr-tooltip="PDF"/>
                    </div>
                </div>
            }>
                <div className="grid">
                    <div className="field col-fixed">
                        <Button
                            label="Novo"
                            icon="pi pi-plus"
                            severity="success"
                            className="flex-none"
                            onClick={() => (window.location.href = "/paciente/cadastro")}
                            disabled={!permissaoCadastro}
                        />
                    </div>
                    <div className="field col" style={{minWidth: "200px"}}>
                        <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search"/>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar..."
                           className="w-full"/>
              </span>

                            <Button icon="pi pi-refresh" onClick={() => pesquisarPacientes()} className="flex-none"
                                    severity="info"/>
                        </div>
                    </div>
                </div>
                <DataTable
                    value={pacientes}
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    emptyMessage="Nenhum registro encontrado!"
                    dataKey="id"
                    filters={filters}
                    globalFilterFields={["nome"]}
                    loading={loading}
                >
                    <Column field="nome" header="Nome"/>
                    <Column field="telefone" header="Telefone"/>
                    <Column field="idade" header="Idade" style={{width: "1rem"}}/>
                    <Column
                        style={{width: "1rem"}}
                        field="edit"
                        body={(paciente) => (
                            <Button
                                icon={permissaoCadastro ? "pi pi-pencil" : "pi pi-eye"}
                                size="small"
                                onClick={() => (window.location.href = `/paciente/cadastro?id=${paciente.id}`)}
                            />
                        )}
                    />
                    <Column hidden={!authService.checkIfHasPermissao(["ADMIN_SUPER"])}
                            style={{width: "1rem"}}
                            body={(paciente) => (<Button
                                icon={"pi pi-trash"}
                                size="small"
                                severity="danger"
                                onClick={() => inativarPaciente(paciente)}
                            />)}
                    />
                </DataTable>
            </Card>
            <ConfirmDialog/>
        </>
    );
};

export default PacientePesquisa;
