import movimentacaoService from "../../../services/movimentacao.service";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import authService from "../../../services/auth.service";

const MovimentacaoPesquisa = ({ mostrarMensagem }) => {
  const permissaoCadastro = authService.checkIfHasPermissao(["CAD_FINANCEIRO_MOVIMENTACAO"]);
  const [loading, setLoading] = useState(true);

  const [movimentacoes, setMovimentacoes] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    pesquisarMovimentacoes();
  }, []);

  const pesquisarMovimentacoes = () => {
    setLoading(true);
    movimentacaoService.pesquisarMovimentacoes().then((result) => {
      setMovimentacoes(result);
      setLoading(false);
    });
  };

  const converterValor = (saldo) => {
    return (
      "R$ " +
      saldo.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  const formatarData = (data) => {
    const dataDate = new Date(data);
    const dia = dataDate.getDate().toString().padStart(2, "0");
    const mes = (dataDate.getMonth() + 1).toString().padStart(2, "0");
    const ano = dataDate.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <>
      <Card title="Movimentações Financeiras">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => (window.location.href = "/financeiro/movimentacao/cadastro")}
              disabled={!permissaoCadastro}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Procurar..."
                  className="w-full"
                />
              </span>

              <Button icon="pi pi-refresh" onClick={() => pesquisarMovimentacoes()} className="flex-none" severity="info" />
            </div>
          </div>
        </div>
        <DataTable
          value={movimentacoes}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          dataKey="id"
          filters={filters}
          globalFilterFields={[
            "contaNome",
            "tipoContaDescricao",
            "situacaoMovimentacaoDescricao",
            "tipoMovimentacaoDescricao",
            "dataMovimentacao",
            "dataVencimento",
            "valor",
          ]}
          loading={loading}
        >
          <Column
            field={(rowData) => {
              return (
                <Tag
                  value={rowData.tipoMovimentacaoDescricao}
                  severity={rowData.tipoMovimentacaoId === 1 ? "success" : "danger"}
                />
              );
            }}
            header="Tipo"
          />
          <Column field="situacaoMovimentacaoDescricao" header="Situação" />
          <Column field={(field) => field.tipoContaDescricao + " - " + field.contaNome} header="Conta" />
          <Column field={(field) => formatarData(field.dataMovimentacao)} header="Data" />
          <Column field={(field) => formatarData(field.dataVencimento)} header="Vencimento" />
          <Column field={(field) => converterValor(field.valor)} header="Valor" />

          <Column
            style={{ width: "1rem" }}
            field="edit"
            body={(movimentacao) => (
              <Button
                icon={permissaoCadastro ? "pi pi-pencil" : "pi pi-eye"}
                size="small"
                onClick={() => (window.location.href = `/financeiro/movimentacao/cadastro?id=${movimentacao.id}`)}
              />
            )}
          />
        </DataTable>
      </Card>
    </>
  );
};

export default MovimentacaoPesquisa;
